import React from "react";

import Layout from "../components/Layout";

const AboutPage = () => (
    <Layout>
        <div id="main">
            <div className="about_section_banner">
                <div className="about_banner">
                    <h1>Acerca de Zion Tech</h1>
                </div>
            </div>
            <div className="inner">
                <div className="about_section_1">
                    <h1 className="about_section_1__title">Conoce más de nosotros</h1>
                    <p className="about_section_1__paragraph">Zion Tech es una empresa de ingeniería pionera en
                        tecnología y recursos integrados de la industria del poliéster reciclado.
                    </p>
                </div>
            </div>
        </div>
    </Layout>
);

export default AboutPage;
